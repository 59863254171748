import "./comunidad.scss";

import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import yellowCircle from "../../assets/vectors/yellow-circle-small.svg";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/SEO/SEO";
import Pagination from "../../components/Pagination/Pagination";
import PostList from "../../views/blog/PostList/PostList";
// import Reviews from "../components/Reviews/Reviews";
import Sidebar from "../../views/blog/Sidebar/Sidebar";
import Hero from "../../views/shared/Hero/Hero";

interface PropsBlogPage extends PropsWithChildren<any> {}

const ComunidadPage: React.FC<PropsBlogPage> = (props: PropsBlogPage) => {
  return (
    <Layout>
      {props.pageContext.humanPageNumber > 1 && (
        <SEO
          title={`Página ${props.pageContext.humanPageNumber} de la Comunidad Almayser. El poder de compartir emociones`}
          description={`Página ${props.pageContext.humanPageNumber}. Conoce todas los consejos y novedades de las terapias florales.`}
        />
      )}
      {props.pageContext.humanPageNumber === 1 && (
        <SEO
          title="Comunidad Almayser. El poder de compartir emociones"
          description="Entra en nuestra comunidad y conoce todas los consejos y novedades de las terapias florales."
          canonical={"comunidad"}
        />
      )}
      <Hero
        title={"Únete a la comunidad"}
        description={[
          "Únete a la comunidad y recibe de regalo una infografía con la información básica de las Flores de Bach.",
        ]}
        is_home={false}
      >
        <StaticImage
          src={"../../assets/images/banco.jpg"}
          alt={"flores"}
          // className={"is-sepia"}
        />
      </Hero>
      <div
        className={
          "container grid-70-30 grid-70-30--no-gap grid-70-30--break-pro"
        }
      >
        <main>
          <div className={" p-vertical"}>
            <img
              src={yellowCircle}
              className={"a-circle-rotate a-circle-rotate--blog"}
              alt={"Círculo"}
            />
            {props.pageContext.humanPageNumber === 1 && (
              <h2>Últimos contenidos de la comunidad AlmaySer</h2>
            )}
            {props.pageContext.humanPageNumber > 1 && (
              <h2>
                Contenidos de la comunidad AlmaySer{" "}
                <small>Página {props.pageContext.humanPageNumber} </small>{" "}
              </h2>
            )}
          </div>
          <PostList posts={props.data.posts.nodes} />
          <div className={"m-blog-pagination"}>
            <Pagination
              nextPagePath={props.pageContext.nextPagePath}
              numberOfPages={props.pageContext.numberOfPages}
              pageNumber={props.pageContext.humanPageNumber}
              previousPagePath={props.pageContext.previousPagePath}
            />
          </div>
        </main>
        <Sidebar />
      </div>
      {/*<Reviews/>*/}
    </Layout>
  );
};

export default ComunidadPage;

export const query = graphql`
  query PostsInPage($skip: Int!, $limit: Int!, $date: Date!) {
    posts: allSanityPost(
      sort: { order: DESC, fields: publishedAt }
      skip: $skip
      limit: $limit
      filter: { publishedAt: { lte: $date } }
    ) {
      nodes {
        slug {
          current
        }
        title
        mainImage {
          ...ImageWithPreview
        }
      }
    }
  }
`;
